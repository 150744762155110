import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import linkUnlinkCatalogue from "src/services/whatsApp/catalogue/linkUnlinkCatalogue";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

export default function CatalogueListDailog({ open, onClose, data, setData }) {
  const { enqueueSnackbar } = useSnackbar();
  const { catalog_account } = data || {};
  const { catalogs } = catalog_account || {};

  // State to keep track of the selected catalog
  const [loading, setLoading] = useState(false);
  const [selectedCatalog, setSelectedCatalog] = useState(null);

  const handleSumbit = async () => {
    try {
      setLoading(true);
      const response = await linkUnlinkCatalogue(selectedCatalog?.id, "1");
      setData(response);
      enqueueSnackbar("Catalog connected Successfully!");
      onClose();
    } catch (error) {
      console.log(error);
      typeof error === "string" && enqueueSnackbar(error, { variant: "error" });
      if (error?.error?.message) {
        enqueueSnackbar(error.error.message, {
          variant: "error",
          autoHideDuration: 10000,
        });
      }
      error?.errors &&
        Object.keys(error?.errors).forEach((key) =>
          enqueueSnackbar(error?.errors[key], {
            variant: "error",
            autoHideDuration: 10000,
          })
        );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ mb: -2 }}>Connect a Catalog</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 13,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 4 }}>
          Connecting a Catalog will allow customers to view, message and send
          carts containing your products and services via WhatsApp.{" "}
          <Link
            href="https://developers.facebook.com/docs/whatsapp/cloud-api/guides/sell-products-and-services"
            target="_blank"
          >
            Learn more
          </Link>
        </Typography>
        <Autocomplete
          size="small"
          id="tags-outlined"
          options={catalogs || []}
          getOptionLabel={(option) =>
            `${option.name}${" "}${" "}${`(${option.id})`}`
          }
          autoHighlight={true}
          onChange={(event, newValue) => setSelectedCatalog(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select catalogue"
              label="Choose a catalogue."
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleSumbit}
        >
          Connect Catalogue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
