import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function setupFbInsta(setupData) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios({
        method: "post",
        url: `lms_user/api/social/fb/setup_account`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: setupData,
      });
      const { login_url, accounts } = await response.data;
      return { login_url, accounts };
    }
  } catch (error) {
    throw new Error(error);
  }
}
