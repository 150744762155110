// react
import { createContext, useReducer, useCallback } from "react";
import { Outlet } from "react-router-dom";
// utils
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

// Create Context
export const ReviewLocationsContext = createContext();

// Initial State
const initialState = {
  locations: null,
  data: [],
  lastPage: null,
  currentPage: null,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { locations } = action.payload;
    return {
      ...state,
      locations,
      lastPage: locations?.last_page,
      data: locations?.data,
      currentPage: locations?.current_page,
    };
  },
  LOAD_MORE: (state, action) => {
    const { locations } = action.payload;
    return {
      ...state,
      locations,
      lastPage: locations?.last_page,
      currentPage: locations?.current_page,
      data: [...state.data, ...locations?.data],
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Provider Component
export const ReviewLocationsContextProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getReviewLocations = useCallback(async (data) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const response = await axios({
          method: "get",
          url: `/lms_user/api/gmb/reviews`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            keyword: data.searchText,
            page: data.page,
            orderBy: data.orderBy,
            orderType: data.orderType,
          },
        });
        const { locations } = await response.data;

        if (data.newPage) {
          dispatch({
            type: "LOAD_MORE",
            payload: {
              locations,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              locations,
            },
          });
        }
      }
    } catch (error) {
      throw new Error(
        Object.keys(error.errors).map((err) => error.errors[err])
      );
    }
  }, []);

  const toggleAutoResponse = async (locData) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const data = new FormData();
        data.append("monitor", "1");
        data.append("account_id", locData.accId);
        data.append("location_id", locData.locId);
        data.append("auto_response", locData.autoRespond);
        const response = await axios({
          method: "post",
          url: `/lms_user/api/gmb/reviews/auto_response_setup`,
          data: data,
        });
        // const { locations } = await response.data;
        // dispatch({
        //   type: "INITIALIZE",
        //   payload: {
        //     locations,
        //   },
        // });
      }
    } catch (error) {
      throw new Error(
        Object.keys(error.errors).map((err) => error.errors[err])
      );
    }
  };

  return (
    <ReviewLocationsContext.Provider
      value={{
        locations: state.locations,
        lastPage: state.lastPage,
        currentPage: state.currentPage,
        data: state.data,
        getReviewLocations,
        toggleAutoResponse,
      }}
    >
      <Outlet />
    </ReviewLocationsContext.Provider>
  );
};
