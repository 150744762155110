import React, { useEffect, useState } from "react";
import "src/components/customized/switchButton/SwitchButton.css";

const SwitchButton = ({ status, toggleStatus, enabledText, disabledText }) => {
  const [isOn, setIsOn] = useState(status);

  useEffect(() => {
    setIsOn(status);
  }, [status]);

  return (
    <label className={`switch-container ${isOn ? "green" : "red"}`}>
      <input type="checkbox" checked={isOn} onChange={toggleStatus} />
      <div className="slider">
        <span className={`label ${isOn ? "left" : "right"}`}>
          {isOn ? enabledText || "Yes" : disabledText || "No"}
        </span>
        <div className="circle"></div>
      </div>
    </label>
  );
};

export default SwitchButton;
