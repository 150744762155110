import React, { useEffect } from "react";
import Router from "./routes";
import ThemeProvider from "./theme";
import "./theme/custom/scrollbar-style.css";
import "./theme/custom/custom-highlight-js.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ThemeSettings from "./components/settings";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import NotistackProvider from "./components/NotistackProvider";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import withClearCache from "./ClearCache";
import "highlight.js/styles/base16/harmonic16-dark.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// ----------------------------------------------------------------------

const changeFavicon = (url) => {
  const link = document.createElement("link");
  link.id = "dynamic-favicon";
  link.rel = "icon";
  link.href = url;

  const oldLink = document.getElementById("dynamic-favicon");
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
};

const setDocumentTitle = (title) => {
  document.title = title;
};

const setMetaDescription = (description) => {
  let metaTag = document.querySelector("meta[name='description']");
  if (!metaTag) {
    metaTag = document.createElement("meta");
    metaTag.name = "description";
    document.head.appendChild(metaTag);
  }
  metaTag.content = description;
};

const setMetaKeywords = (keywords) => {
  let metaTag = document.querySelector("meta[name='keywords']");
  if (!metaTag) {
    metaTag = document.createElement("meta");
    metaTag.name = "keywords";
    document.head.appendChild(metaTag);
  }
  metaTag.content = keywords;
};

const validateLocalStorageItem = (item) => item && item !== "null";

// ----------------------------------------------------------------------

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  useEffect(() => {
    try {
      const faviconLogo = localStorage.getItem("faviconLogo");
      const metaTitle = localStorage.getItem("metaTitle");
      const metaDescription = localStorage.getItem("metaDiscription");
      const metaKeywords = localStorage.getItem("metaKeywords");

      const title = validateLocalStorageItem(metaTitle) && metaTitle;

      setDocumentTitle(title);

      const favicon = validateLocalStorageItem(faviconLogo) && faviconLogo;

      changeFavicon(favicon);

      if (validateLocalStorageItem(metaDescription)) {
        setMetaDescription(metaDescription);
      }

      if (validateLocalStorageItem(metaKeywords)) {
        setMetaKeywords(metaKeywords);
      }
    } catch (error) {
      console.error("Error setting document metadata:", error);
    }
  }, []);

  return <ClearCacheComponent />;
}

function MainApp() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <NotistackProvider>
              <ProgressBarStyle />
              <ScrollToTop />
              <Router />
            </NotistackProvider>
          </LocalizationProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}

export default App;
