import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function manageApiKeys(
  type,
  apiKey,
  developerEmail,
  appID
) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios({
        method: type === "update" ? "patch" : type === "add" ? "post" : "get",
        url:
          type === "update"
            ? `lms_user/api/gmb/map_api_keys/${appID}`
            : `lms_user/api/gmb/map_api_keys`,
        params: (type === "update" || type === "add") && {
          api_key: apiKey,
          developer_email: developerEmail,
        },
      });
      const res = response.data;
      return res;
    }
  } catch (error) {
    throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
  }
}
