import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  landingLogo: PropTypes.string,
};

export default function Logo({ disabledLink = false, sx, landingLogo }) {
  // OR
  // const logo = '/logo/logo_single.svg';

  const logo = (
    <Box sx={{ width: 35, height: "auto", ...sx }}>
      <img src={landingLogo !== "null" && landingLogo} alt="icon" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
