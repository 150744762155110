import { useState, useMemo } from "react";
import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import {
  InputLabel,
  FormControl,
  TextField,
  ListSubheader,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// ----------------------------------------------------------------------

RHFSearchableSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function RHFSearchableSelect({
  name,
  selectOptions,
  label,
  children,
  ...other
}) {
  const { control } = useFormContext();

  const [searchText, setSearchText] = useState("");

  const displayedOptions = useMemo(
    () => selectOptions.filter((option) => containsText(option, searchText)),
    [searchText, selectOptions]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <InputLabel id="search-select-label">{label}</InputLabel>
          <Select
            MenuProps={{ autoFocus: false }}
            labelId="search-select-label"
            id="search-select"
            {...field}
            value={field.value}
            label={label}
            onClose={() => setSearchText("")}
            onChange={field.onChange}
            // This prevents rendering empty string in Select's value
            // if search text would exclude currently selected option.
            renderValue={() => field.value}
          >
            {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
            <ListSubheader>
              <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayedOptions.map((option, i) => (
              <MenuItem key={i} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
