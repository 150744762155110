import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function updateAliasName(
  accountId,
  locationId,
  aliasName
) {
  try {
    let formData = new FormData();
    formData.append("account_id", accountId);
    formData.append("location_id", locationId);
    formData.append("alias_name", aliasName);

    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios({
        method: "post",
        url: `lms_user/api/gmb/alias_name`,
        data: formData,
      });
      const res = response.data;
      return res;
    }
  } catch (error) {
    throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
  }
}
