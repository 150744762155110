// react
import { createContext, useReducer, useCallback } from "react";
import { Outlet } from "react-router-dom";
// utils
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

// Create Context
export const LocationReviewsContext = createContext();

// Initial State
const initialState = {
  reviews: [],
  lastPage: 1,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { reviews } = action.payload;
    return { ...state, reviews: reviews?.data, lastPage: reviews?.last_page };
  },
  UPDATE_REVIEW_REPLY: (state, action) => {
    const { review } = action.payload;
    const { reviews } = state;
    const updatedReviews = reviews.map((rev) =>
      rev.review_id === review.review_id ? review : rev
    );
    return { ...state, reviews: updatedReviews };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Provider Component
export const LocationReviewsContextProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getLocationReviews = useCallback(async (locData) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const requestConfig = {
          method: "get",
          url: `lms_user/api/gmb/reviews/list_reviews/${locData.locId}`,
          params: {
            page: locData.page,
            keyword: locData.searchText,
            dateRange: locData.dateRange,
            ratings: locData.ratingFilter,
            orderBy: locData.orderBy,
            orderType: locData.orderType,
          },
        };
        const response = await axios(requestConfig);
        const { reviews } = await response.data;
        dispatch({
          type: "INITIALIZE",
          payload: { reviews },
        });
        return;
      }
    } catch (error) {
      throw new Error(
        Object.keys(error.errors).map((err) => error.errors[err])
      );
    }
  }, []);

  const updateReply = async (replyData) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const data = new FormData();
        data.append("account_id", replyData.accId);
        data.append("location_id", replyData.locId);
        data.append("review_id", replyData.reviewId);
        data.append("review", replyData.replyText);
        const requestConfig = {
          method: "post",
          url: `lms_user/api/gmb/reviews/update_review`,
          data: data,
        };
        const response = await axios(requestConfig);
        const { review } = await response.data;
        dispatch({
          type: "UPDATE_REVIEW_REPLY",
          payload: { review },
        });
      }
    } catch (error) {
      throw new Error(
        Object.keys(error.errors).map((err) => error.errors[err])
      );
    }
  };

  const deleteReply = async (replyData) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const data = new FormData();
        data.append("account_id", replyData.accId);
        data.append("location_id", replyData.locId);
        data.append("review_id", replyData.reviewId);
        const requestConfig = {
          method: "post",
          url: `lms_user/api/gmb/reviews/delete_review`,
          data: data,
        };
        const response = await axios(requestConfig);
        const { review } = await response.data;
        dispatch({
          type: "UPDATE_REVIEW_REPLY",
          payload: { review },
        });
      }
    } catch (error) {
      throw new Error(
        Object.keys(error.errors).map((err) => error.errors[err])
      );
    }
  };

  return (
    <LocationReviewsContext.Provider
      value={{
        reviews: state.reviews,
        lastPage: state.lastPage,
        getLocationReviews,
        updateReply,
        deleteReply,
      }}
    >
      <Outlet />
    </LocationReviewsContext.Provider>
  );
};
