import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function getCatalogueSetup() {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios({
        method: "get",
        url: `lms_campaign/api/whatsapp/catalog/setup`,
      });
      const res = response.data;
      return res;
    }
  } catch (error) {
    throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
  }
}
