import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContentText } from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import linkUnlinkCatalogue from "src/services/whatsApp/catalogue/linkUnlinkCatalogue";

export default function DisconnectCatalogue({ open, onClose, data, setData }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { connected_catalog } = data || {};
  const { id } = connected_catalog || {};

  const handleDisconnect = async () => {
    try {
      setLoading(true);
      const response = await linkUnlinkCatalogue(id, "0");
      setData(response);
      enqueueSnackbar("Catalog Disconnected Successfully!");
      onClose();
    } catch (error) {
      console.log(error);
      typeof error === "string" && enqueueSnackbar(error, { variant: "error" });
      if (error?.error?.message) {
        enqueueSnackbar(error.error.message, {
          variant: "error",
          autoHideDuration: 10000,
        });
      }
      error?.errors &&
        Object.keys(error?.errors).forEach((key) =>
          enqueueSnackbar(error?.errors[key], {
            variant: "error",
            autoHideDuration: 10000,
          })
        );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ mb: 2 }}>{"Disconnect Catalogue"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to disconnect the catalogue?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          loading={loading}
          sx={{ mx: 1 }}
          onClick={handleDisconnect}
        >
          Disconnect Catalogue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
