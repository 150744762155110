// react
import { createContext, useReducer, useCallback, useEffect } from "react";
import { Outlet } from "react-router-dom";
import getReportList from "src/services/reports/getReportList";

// Initial State
const initialState = {
  reportList: [],
  loading: true,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { reports } = action.payload;
    return { ...state, reportList: reports, loading: false };
  },
  UPDATE_REPORT_LIST: (state, action) => {
    const { reportList } = action.payload;
    return {
      ...state,
      reportList: reportList,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const ReportSettingsContext = createContext();

// Provider Component
export const ReportSettingsProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      const response = await getReportList();
      const { reports } = await response;

      dispatch({
        type: "INITIALIZE",
        payload: {
          reports,
        },
      });
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const updateReportList = useCallback((reportList) => {
    dispatch({ type: "UPDATE_REPORT_LIST", payload: { reportList } });
  }, []);

  useEffect(() => {
    initialize();
  }, []);

  return (
    <ReportSettingsContext.Provider
      value={{
        reportList: state.reportList,
        loading: state.loading,
        updateReportList,
      }}
    >
      <Outlet />
    </ReportSettingsContext.Provider>
  );
};
