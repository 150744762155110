// utils
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function deleteTwitterAccount(userID) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios({
        method: "delete",
        url: `lms_user/api/social/twitter/remove_account/${userID}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const res = await response.data;
      return res;
    }
  } catch (error) {
    throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
  }
}
