// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router";
// utils
import axios from "../../utils/axios";
import { isValidToken } from "../../utils/jwt";

// Initial State
const initialState = {
  org_units: null,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { org_units } = action.payload;
    return {
      ...state,
      org_units,
    };
  },
  CREATE_ORG_UNIT: (state, action) => {
    const { org_unit } = action.payload;
    const updatedState = [...state.org_units];
    const addToParent = () => {
      const toUpdate = updatedState[0].childs.findIndex(
        (orgUnitItem) => orgUnitItem.node_id === org_unit.node_id
      );
      // updatedState[0].childs[toUpdate].childs.push(org_unit);
      console.log(updatedState[0].childs[toUpdate]);
    };
    org_unit.parent === null
      ? updatedState[0].childs.push(org_unit)
      : addToParent();
    return {
      ...state,
      org_units: updatedState,
    };
  },
  UPDATE_ORG_UNIT: (state, action) => {
    const { org_unit } = action.payload;
    const updated = [...state.org_units];
    const toUpdate = updated[0].childs.findIndex(
      (orgUnitItem) => orgUnitItem.node_id === org_unit.node_id
    );
    updated[0].childs[toUpdate] = org_unit;
    return {
      ...state,
      org_units: updated,
    };
  },
  DELETE_ORG_UNIT: (state, action) => {
    const { deletedOrgUnitID } = action.payload;
    const updated = [...state.org_units];
    const toUpdate = updated[0].childs.findIndex(
      (orgUnitItem) => orgUnitItem.node_id === deletedOrgUnitID
    );
    updated[0].childs.splice(toUpdate, 1);
    return {
      ...state,
      org_units: updated,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const OrgUnitContext = createContext();

// Provider Component
export const OrgUnitProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const orgID = JSON.parse(localStorage.getItem("profile")).org_id;
      const controller = new AbortController();
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios.get(
          `/lms_user/api/org_unit?org_id=${orgID}`,
          { signal: controller.signal },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const { org_units } = await response.data;
        // console.log(response.data);
        // cancel the request
        controller.abort();
        dispatch({
          type: "INITIALIZE",
          payload: {
            org_units,
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            resellers: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          resellers: null,
        },
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const createOrgUnit = async (newOrgUnit, selectedParent) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const orgID = JSON.parse(localStorage.getItem("profile")).org_id;
      const data = new FormData();
      selectedParent !== "" && data.append("parent", selectedParent);
      data.append("node_name", newOrgUnit);
      data.append("org_id", orgID);
      await axios({
        method: "post",
        url: "/lms_user/api/org_unit",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      // const { org_unit } = await response.data;
      initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const updateOrgUnit = async (updatedOrgUnit, nodeID, selectedParent) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const orgID = JSON.parse(localStorage.getItem("profile")).org_id;
      const data = new FormData();
      selectedParent !== "" && data.append("parent", selectedParent);
      data.append("org_id", orgID);
      data.append("node_name", updatedOrgUnit);
      data.append("_method", "patch");
      await axios({
        method: "post",
        url: `/lms_user/api/org_unit/${nodeID}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const deleteOrgUnit = async (deletedOrgUnitID) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const data = new FormData();
      data.append("_method", "delete");
      await axios({
        method: "post",
        url: `/lms_user/api/org_unit/${deletedOrgUnitID}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  return (
    <OrgUnitContext.Provider
      value={{
        org_units: state.org_units,
        createOrgUnit,
        updateOrgUnit,
        deleteOrgUnit,
      }}
    >
      <Outlet />
    </OrgUnitContext.Provider>
  );
};
