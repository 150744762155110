import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
import deleteTwitterAccount from "src/services/socialPost/twitter/deleteTwitterAccount";
import getAccounts from "src/services/socialPost/twitter/getAccounts";
import setupTwitterAccount from "src/services/socialPost/twitter/setupTwitterAccount";

//  Create context
export const TwitterContext = createContext();

//  Initial state
const initialState = {
  login_settings: null,
  accounts: null,
  loading: true,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { login_settings, accounts } = action.payload;
    return { ...state, login_settings, accounts, loading: false };
  },
};

//  Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

//  Create provider
export const TwitterContextProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      const response = await getAccounts();
      const { login_settings, accounts } = await response;
      dispatch({
        type: "INITIALIZE",
        payload: {
          login_settings,
          accounts,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const setupTwitter = async (setupData) => {
    try {
      const response = await setupTwitterAccount(setupData);
      const { login_settings, accounts } = await response;
      dispatch({
        type: "INITIALIZE",
        payload: {
          login_settings,
          accounts,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const deleteAccount = async (userID) => {
    try {
      const response = await deleteTwitterAccount(userID);
      const { login_settings, accounts } = await response;
      dispatch({
        type: "INITIALIZE",
        payload: {
          login_settings,
          accounts,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => initialize(), []);

  return (
    <TwitterContext.Provider
      value={{
        login_settings: state.login_settings,
        accounts: state.accounts,
        loading: state.loading,
        setupTwitter,
        deleteAccount,
      }}
    >
      <Outlet />
    </TwitterContext.Provider>
  );
};
