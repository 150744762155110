import * as Yup from "yup";
import { useEffect, useMemo, useContext } from "react";
import { useSnackbar } from "notistack";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import { Box, Stack } from "@mui/material";
// components
import { FormProvider, RHFTextField } from "src/components/hook-form";
// context
import { AuthContext } from "src/contexts/JWTContext";

// ----------------------------------------------------------------------

export default function ForgotPwdOtpForm({ requestStatus }) {
  const { requestOTP } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const ForgotPwdOtpFormSchema = Yup.object().shape({
    forgotPwdMobile: Yup.string().required("Agent ID is required"),
  });

  const defaultValues = useMemo(
    () => ({
      forgotPwdMobile: "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(ForgotPwdOtpFormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    // setError,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const showError = (err) => {
  //   err.message &&
  //     setError("agentID", {
  //       type: "custom",
  //       message: err.message,
  //     });
  // };

  const onRequestOTP = async (data) => {
    try {
      await requestOTP(data.forgotPwdMobile);
      requestStatus();
      enqueueSnackbar("OTP sent successfully!");
      reset();
    } catch (error) {
      // showError(JSON.parse(error.message));
      enqueueSnackbar(`Failed to send OTP \n ${error.message}`, {
        variant: "error",
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onRequestOTP)}>
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "grid",
            columnGap: 2,
            rowGap: 3,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
            },
          }}
        ></Box>
        <Box sx={{ mb: 2 }}>
          <p>Enter registered mobile number</p>
          <small>A OTP will be sent to your registered mobile</small>
        </Box>
        <Stack alignItems="flex-start">
          <RHFTextField name="forgotPwdMobile" label="Mobile number" />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 2 }}
          >
            Request OTP
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
