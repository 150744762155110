import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function removeFbAccount(fbId) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios({
        method: "delete",
        url: `lms_user/api/social/fb/setup_account/${fbId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const { success } = await response.data;
      return success;
    }
  } catch (error) {
    throw new Error(error);
  }
}
