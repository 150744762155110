import axios from "./axios";
import { setSession } from "./jwt";

const refreshToken = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await axios({
      method: "post",
      url: `lms_user/api/refresh`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { refresh_token: refreshToken },
    });
    const { access_token, refresh_token } = await response.data;
    setSession(access_token, null, refresh_token);
    // console.log("token refreshed");
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export default refreshToken;
