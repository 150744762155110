import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function getAllPosts(page, pageSize) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios.get(`lms_user/api/social/post`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { page_size: pageSize, page: page },
      });
      const { posts } = await response.data;
      return posts;
    }
  } catch (error) {
    throw new Error(error);
  }
}
