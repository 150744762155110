import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
import getUnsubscribersList from "src/services/whatsApp/unsubscribersList/getUnsubscribersList";

//  Create context
export const UnsubscribersListContext = createContext();

//  Initial state
const initialState = {
  numbers: {},
  keywords: [],
  time_period: {},
  loading: true,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { numbers, keywords, time_period } = action.payload;
    return { ...state, numbers, keywords, time_period, loading: false };
  },
  UPDATEDATA: (state, action) => {
    const { numbers, keywords, time_period } = action.payload;
    return { ...state, numbers, keywords, time_period };
  },
};

//  Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

//  Create provider
export const UnsubscribersListProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      const response = await getUnsubscribersList();
      const { numbers, keywords, time_period } = response;
      dispatch({
        type: "INITIALIZE",
        payload: {
          numbers,
          keywords,
          time_period,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const updateData = async (numbers, keywords, time_period) => {
    try {
      dispatch({
        type: "UPDATEDATA",
        payload: {
          numbers,
          keywords,
          time_period,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => initialize(), []);

  return (
    <UnsubscribersListContext.Provider
      value={{
        numbers: state.numbers,
        keywords: state.keywords,
        timePeriod: state.time_period,
        loading: state.loading,
        initialize,
        updateData,
      }}
    >
      <Outlet />
    </UnsubscribersListContext.Provider>
  );
};
