// react
import {
  createContext,
  useEffect,
  useReducer,
  useContext,
  useCallback,
} from "react";
import { Outlet } from "react-router-dom";
// utils
import axios from "../../utils/axios";
import { isValidToken } from "../../utils/jwt";
//context
import { ChatSettingsContext } from "../chatApp/ChatSettingsContext";
// Initial State
const initialState = {
  locations: null,
  chat_agents: null,
  brandId: null,
  loading: true,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { locations, chat_agents, brandId } = action.payload;
    return {
      ...state,
      locations,
      chat_agents,
      brandId,
      loading: false,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const GmbLocationContext = createContext();

// Provider Component
export const GmbLocationProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { brand } = useContext(ChatSettingsContext);
  const brandID = brand ? brand?.brand_id : "";
  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const controller = new AbortController();
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios({
          method: "get",
          url: `/lms_chat/api/business_messages/locations`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: { brand_id: brandID },
        });
        const { locations, chat_agents } = await response.data;
        // cancel the request
        controller.abort();
        dispatch({
          type: "INITIALIZE",
          payload: {
            locations,
            chat_agents,
            brandId: brandID,
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            locations: null,
            chat_agents: null,
            brandId: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          locations: null,
          chat_agents: null,
          brandId: null,
        },
      });
    }
  }, [brandID]);

  useEffect(() => {
    if (brandID) {
      initialize();
    }
  }, [brandID, initialize]);

  const addLocation = async (locationData, nodeID) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axios({
        method: "post",
        url: `/lms_chat/api/business_messages/location`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          brand_id: brandID,
          place_id: locationData.placeId,
          location_id: locationData.locationId,
          node_id: nodeID,
          user_id: locationData.user,
        },
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const updateLocation = async (
    locIds,
    chatAgentId,
    chatAgentName,
    takeoverAgent
  ) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const data = new FormData();
      data.append("location_ids", locIds);
      data.append("chat_agent_id", chatAgentId);
      data.append("chat_agent_name", chatAgentName);
      data.append("takeover_agent", takeoverAgent);
      await axios({
        method: "post",
        url: `/lms_chat/api/business_messages/update_location`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const createChatAgent = async (userID) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axios({
        method: "post",
        url: `/lms_chat/api/chat_agent`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          user_id: userID,
        },
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const deleteMultiLocations = async (locationIds) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const data = new FormData();
      data.append("location_ids", locationIds);
      await axios({
        method: "post",
        url: `/lms_chat/api/business_messages/remove_location`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const syncLocation = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      await axios({
        method: "get",
        url: `/lms_chat/api/business_messages/sync_location`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: { brand_id: brandID },
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const updateMultiLocations = async (locationIds, nodeId, userId) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const data = new FormData();
      data.append("location_ids", locationIds);
      data.append("user_id", userId);
      data.append("node_id", nodeId);
      await axios({
        method: "post",
        url: `/lms_chat/api/business_messages/update_location`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      await initialize();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const filterLocations = async (filterData) => {
    const data = {};
    data.brand_id = brandID;
    data.filters = JSON.stringify(filterData);
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "get",
        url: `/lms_chat/api/business_messages/location`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: data,
      });
      const { locations } = await response.data;
      dispatch({
        type: "INITIALIZE",
        payload: {
          locations,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  return (
    <GmbLocationContext.Provider
      value={{
        locations: state.locations,
        chatAgents: state.chat_agents,
        brandId: state.brandId,
        loading: state.loading,
        addLocation,
        createChatAgent,
        updateLocation,
        deleteMultiLocations,
        syncLocation,
        updateMultiLocations,
        filterLocations,
      }}
    >
      <Outlet />
    </GmbLocationContext.Provider>
  );
};
